<template>
    <v-container fluid>
        <v-row>
            <v-col 
                cols="6"
                lg="8"
                md="8"
                sm="6"
            >
                <h3>Produto</h3>
            </v-col>
            <v-col 
                cols="6"
                lg="4"
                md="4"
                sm="6"
                style="text-align: right;"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            :to="{ name: 'produtoForm', params: {id: 0} }"
                        >
                            Novo Produto
                        </v-btn>
                    </template>
                    <span>Novo Produto</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row style="height: 70px;">
            <v-col 
                cols="6"
                lg="11"
                md="10"
                sm="8"
            >
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        :label="$t('pesquisaRapida')"
                        append-outer-icon="mdi mdi-magnify"
                        single-line
                        outlined
                        @click="options.page=1;"
                        @click:append-outer="getRegisters"
                    ></v-text-field>
                </v-form>
            </v-col>
            <v-col 
                cols="6"
                lg="1"
                md="2"
                sm="4"
                style="text-align: right; margin-top: 10px;"
            >
                <v-menu
                    bottom
                    origin="center center"
                    transition="scale-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="white"
                            style="color: gray !important"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="gerarExcel">
                            <v-list-item-title>
                                <v-icon left>mdi-file-excel</v-icon> Gerar Excel
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="gerarExcelCodigoFechado">
                            <v-list-item-title>
                                <v-icon left>mdi-file-export-outline</v-icon> Exportar códigos fechados
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="importarExcelCodigoFechado">
                            <v-list-item-title>
                                <v-icon left>mdi-database-import-outline</v-icon> Importar códigos fechados
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="gerarExcelCodigoParametrizavel">
                            <v-list-item-title>
                                <v-icon left>mdi-file-export-outline</v-icon> Exportar códigos parametrizáveis
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="importarExcelCodigoParametrizavel">
                            <v-list-item-title>
                                <v-icon left>mdi-database-import-outline</v-icon> Importar códigos parametrizáveis
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    :no-data-text="$t('nenhumRegistroEncontrado')"
                    :footer-props="{
                        'items-per-page-text':$t('registrosPorPagina'),
                        'items-per-page-all-text':$t('todos'),
                        pageText: '{0}-{1} de {2}'
                    }"
                >
                
                    <template v-slot:item.statusDescription="{ item }">
                        <v-chip
                            :color="getStatusColor(item.status)"
                            outlined
                            dark
                        >
                            {{ item.statusDescription }}
                        </v-chip>
                    </template>

                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'produtoForm'"
                            :showButtons="{
                                edit: true,
                                cancel: item.status === 1 ? true : false,
                                active: item.status === 0 ? true : false,
                                clonar: true
                            }"
                            @confirmCancel="confirmCancel"
                            @confirmActive="confirmActive" 
                            @confirmClone="confirmClone"
                        />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

        <UploadFile 
            v-on:methodConfirmAfterUpload="dialogUploadFile.methodAfterUpload"
            :showUploadFile.sync="dialogUploadFile.show"
            :idKey="dialogUploadFile.idKey"
            :reload="dialogUploadFile.reload"
            :acceptType="dialogUploadFile.acceptType"
            :typeAttachment="dialogUploadFile.typeAttachment"
            :typeDocument="dialogUploadFile.typeDocument"
            :multipleFiles="dialogUploadFile.multipleFiles"
            :showMsgConfirmation="dialogUploadFile.showMsgConfirmation"
            :msgConfirmation="dialogUploadFile.msgConfirmation"
        />
        
        <DialogMessage 
            v-on:methodOKToCall="dialogMessage.methodOK"
            :showDialogMessage.sync="dialogMessage.show"
            :headerTitle="dialogMessage.headerText"
            :headerColor="dialogMessage.headerColor"
            :headerFontColor="dialogMessage.headerFontColor"
            :bodyText="dialogMessage.bodyText"
            :useEmTag="dialogMessage.useEmTag"
            :widthDialog="dialogMessage.widthDialog"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import UploadFile from "@/components/File/UploadFile";
    import DialogMessage from "@/components/Layout/DialogMessage";
    import { 
        TIPO_DOCUMENTO, 
        TIPO_ANEXO
    } from "@/utilities/Enums";

    export default ({

        components: {
            ActionList,
            ActionDialog,
            UploadFile,
            DialogMessage
        },

        mixins: [Helpers],

        data: vue => ({

            loading: false,

            statusId: 0,
            
            page: 1,

            filter: {
                fastSearch: ''
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: "Data Cadastro", value: "dataCadastroFormatted", sortable: true },
                { text: "Código Fechado", value: "codigoFechadoDescription", sortable: true},
                { text: vue.$t('marca'), value: "marcaDescricao", sortable: true },
                { text: vue.$t('codigo'), value: "codigo", sortable: true },
                { text: vue.$t('descricao'), value: "descricao", sortable: true, width: "40%" },
                { text: "Tipo do Produto", value: "tipoProdutoDescricao", sortable: true },
                { text: "Indisponível?", value: "obsoletoDescricao", sortable: true, align: "center" },
                { text: vue.$t('status'), value: "statusDescription", sortable: true},
                { text: vue.$t('acoes'), value: "action", sortable: false, align: "center" }
            ],

            options: { 
                rowsPerPage: 10, 
                page: 2 
            },

            dialogUploadFile: {
                show: false,
                idKey: 0,
                reload: 0,
                acceptType: ".xlsx,.xls",
                typeAttachment: 0,
                typeDocument: 0,
                multipleFiles: false,
                showMsgConfirmation: true,
                msgConfirmation: "",
                methodAfterUpload: ( () => {} ),
            },

            dialogMessage: {
                show: false,
                headerText: "",
                headerColor: "",
                headerFontColor: "",
                bodyText: "",
                useEmTag: true,
                widthDialog: "700",
                methodOK: ( () => {} ),
            },

            listProduto: []
        }),

        computed: {

            filteredSearch: function() {
                return this.listProduto.filter((produtoFilter) => {
                    
                    let filter = this.filter.fastSearch.toLowerCase();

                    var dataCadastroFormatted = produtoFilter.dataCadastroFormatted.toLowerCase().match(filter)
                    var codigoFechadoDescription = produtoFilter.codigoFechadoDescription.toLowerCase().match(filter)
                    var marcaDescricao = produtoFilter.marcaDescricao.toLowerCase().match(filter)
                    var codigo = produtoFilter.codigo.toLowerCase().match(filter)
                    var descricao = produtoFilter.descricao.toLowerCase().match(filter)
                    var tipoProdutoDescricao = produtoFilter.tipoProdutoDescricao.toLowerCase().match(filter)
                    var statusDescription = produtoFilter.statusDescription.toLowerCase().match(filter)
                    var obsoletoDescricao = produtoFilter.obsoletoDescricao.toLowerCase().match(filter)
                    var id = produtoFilter.id.toString().match(filter);

                    if(dataCadastroFormatted != null) { return dataCadastroFormatted; }
                    else if(codigoFechadoDescription != null) { return codigoFechadoDescription; } 
                    else if(codigo != null) { return codigo; } 
                    else if(descricao != null) { return descricao; } 
                    else if(tipoProdutoDescricao != null) { return tipoProdutoDescricao; } 
                    else if(marcaDescricao != null) { return marcaDescricao; } 
                    else if(statusDescription != null) { return statusDescription; } 
                    else if(obsoletoDescricao != null) { return obsoletoDescricao; } 
                    else { return id; }
                });
            }
        },

        methods: {

            getStatusColor (statusDescription) {

                let color = "";

                switch (statusDescription) {
                    
                    //ATIVAR
                    case 1:
                        color = 'var(--color__main)';
                        break;

                    //INATIVAR 
                    case 0:
                        color = 'var(--color__status_inactive)';
                        break;
                
                    default:
                        break;
                }

                return color
            },
            
            async getRegisters() {

                this.listProduto = await this.$store.dispatch("produtoModule/List");
            },

            confirmCancel(id) {

                this.statusId = 0;

                this.dialog = {
                    show: true,
                    headerText: this.$t('confirmacao'),
                    bodyText: 'Você irá INATIVAR este Produto, confirma sua decisão?',
                    methodConfirm: this.cancel,
                    params: id
                };
            },

            async cancel(id) {

                let statusId = this.statusId;

                this.showLoading();

                let result = await this.$store.dispatch("produtoModule/AlterarStatus", { id, statusId });

                if (result.success) {
                    this.showToast("success", this.$t('sucesso'), result.message);
                }
                else {
                    this.showToast("error", "Erro!", result.message);
                }

                this.getRegisters();

                this.hideLoading();
            },

            confirmActive(id) {

                this.statusId = 1;

                this.dialog = {
                    show: true,
                    headerText: this.$t('confirmacao'),
                    bodyText: 'Você irá ATIVAR este Produto no sistema, confirma sua decisão?',
                    methodConfirm: this.active,
                    params: id
                };

            },

            async active(id) {

                this.showLoading();
                let statusId = this.statusId;

                let result = await this.$store.dispatch("produtoModule/AlterarStatus", { id, statusId });

                if (result.success) {
                    this.showToast("success", this.$t('sucesso'), result.message);
                }
                else {
                    this.showToast("error", "Erro!", result.message);
                }

                this.getRegisters();

                this.hideLoading();
            },

            confirmClone(id) {

                this.dialog = {
                    show: true,
                    headerText: this.$t('confirmacao'),
                    bodyText: 'Você irá CLONAR esse produto, confirma sua decisão?',
                    methodConfirm: this.clonar,
                    params: id
                };

            },

            async clonar(id) {

                this.showLoading();

                this.$router.push({ name: "produtoForm", params: {id, clonar: true} });

                this.hideLoading();
            },
            
            async gerarExcel() {
                
                this.showLoading();

                this.$spTechApi.get(`produto/exportar-excel`, {
                        responseType: "blob",
                    })
                    .then((response) => {

                        var blob = new Blob([response.data], {
                            type: "application/octet-stream",
                        });

                        var fileName = "Produtos.xlsx";

                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");

                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.download = fileName;
                        a.click();
                        window.URL.revokeObjectURL(url);

                        this.hideLoading();
                    })
                    .catch(error => {
                        
                        let errorMessage = "";
                        if (error.response && error.response.status === 400) {
                            errorMessage = `Erro ao gerar excel: [${error.response.data}]`;
                        }
                        else {
                            errorMessage = `Erro ao gerar excel: [${error}]`;
                        }
                        
                        this.showToast("error", "Erro!", errorMessage);
                        this.hideLoading();
                    }
                );
            },
            
            async gerarExcelCodigoFechado() {
                
                this.showLoading();

                this.$spTechApi.get(`produto/exportar-excel-codigo-fechado`, {
                        responseType: "blob",
                    })
                    .then((response) => {

                        var blob = new Blob([response.data], {
                            type: "application/octet-stream",
                        });

                        var fileName = "ProdutosCodigosFechados.xlsx";

                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");

                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.download = fileName;
                        a.click();
                        window.URL.revokeObjectURL(url);

                        this.hideLoading();
                    })
                    .catch(error => {
                        
                        let errorMessage = "";
                        if (error.response && error.response.status === 400) {
                            errorMessage = `Erro ao gerar excel: [${error.response.data}]`;
                        }
                        else {
                            errorMessage = `Erro ao gerar excel: [${error}]`;
                        }
                        
                        this.showToast("error", "Erro!", errorMessage);
                        this.hideLoading();
                    }
                );
            },

            importarExcelCodigoFechado() {
                this.dialogUploadFile = {
                    show: true,
                    idKey: this.id,
                    reload: Math.random(),
                    acceptType: ".xlsx,.xls",
                    typeAttachment: TIPO_ANEXO.PRODUTO.value,
                    typeDocument: TIPO_DOCUMENTO.PLANILHA_CODIGO_FECHADO.value,
                    multipleFiles: false,
                    showMsgConfirmation: true,
                    msgConfirmation: "Você está prestes a importar a planilha de código fechado no sistema, confirma sua decisão?",
                    methodAfterUpload: this.importarPlanilha,
                };
            },
            
            async gerarExcelCodigoParametrizavel() {
                
                this.showLoading();

                this.$spTechApi.get(`produto/exportar-excel-codigo-parametrizavel`, {
                        responseType: "blob",
                    })
                    .then((response) => {

                        var blob = new Blob([response.data], {
                            type: "application/octet-stream",
                        });

                        var fileName = "ProdutosCodigosParametrizaveis.xlsx";

                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement("a");

                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.download = fileName;
                        a.click();
                        window.URL.revokeObjectURL(url);

                        this.hideLoading();
                    })
                    .catch(error => {
                        
                        let errorMessage = "";
                        if (error.response && error.response.status === 400) {
                            errorMessage = `Erro ao gerar excel: [${error.response.data}]`;
                        }
                        else {
                            errorMessage = `Erro ao gerar excel: [${error}]`;
                        }
                        
                        this.showToast("error", "Erro!", errorMessage);
                        this.hideLoading();
                    }
                );
            },

            importarExcelCodigoParametrizavel() {
                this.dialogUploadFile = {
                    show: true,
                    idKey: this.id,
                    reload: Math.random(),
                    acceptType: ".xlsx,.xls",
                    typeAttachment: TIPO_ANEXO.PRODUTO.value,
                    typeDocument: TIPO_DOCUMENTO.PLANILHA_CODIGO_PARAMETRIZAVEL.value,
                    multipleFiles: false,
                    showMsgConfirmation: true,
                    msgConfirmation: "Você está prestes a importar a planilha de códigos parametrizáveis no sistema, confirma sua decisão?",
                    methodAfterUpload: this.importarPlanilha,
                };
            },

            async importarPlanilha(arquivoRequest) {
                
                this.showLoading();

                const result = await this.$store.dispatch("produtoModule/ImportarPlanilha", arquivoRequest);

                this.hideLoading();

                this.dialogMessage = {
                    show: true,
                    headerText: 'Log da importação',
                    headerColor: 'var(--color__main)',
                    headerFontColor: 'white',
                    bodyText: result,
                    useEmTag: true,
                    widthDialog: "900",
                    methodOK: (() => { 
                        this.getRegisters(); 
                        this.dialogMessage.show = false; 
                    }),
                };
            },
        },

        async created() {
            this.showLoading();

            await this.getRegisters();

            this.hideLoading();
        }
    })
</script>
